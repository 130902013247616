import { Box, styled } from "@mui/material";
export const StyleDivisor = styled(Box) `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 8px 0;
  cursor: pointer;
  position: relative;
  width: 100%;

  & > span {
    display: inline-block;
    color: ${({ theme }) => /* @ts-ignore */ theme.palette.primary.text};
    font-family: "IBM Plex Serif Italic", serif;
    position: absolute;
    z-index: 10;
  }

  & > #_current_page {
    font-size: 7.5rem;
    z-index: 1;
    width: 100%;
    opacity: 0.1;
    top: 0;
    left: 0;
    text-align: center;
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  &:after {
    position: absolute;
    content: "";
    width: 40%;
    height: 1px;
    background: ${({ theme }) => theme.palette.secondary.light};
    top: 50%;
    left: 0;
  }

  &:before {
    position: absolute;
    content: "";
    width: 40%;
    height: 1px;
    background: ${({ theme }) => theme.palette.secondary.light};
    top: 50%;
    right: 0;
  }

  & > ._icon_wrapper span {
    color: ${({ theme }) => 
// @ts-ignore
theme.palette.primary.text};
  }

  /* transition: all 0.3s;
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }*/
`;

import { theme } from "@docaposte-agility/da-design-system";
import { createTheme } from "@mui/material/styles";
const overrideKey = "customization";
const primary = {
    text: "#FFFFFF",
};
const secondary = {
    light: "#666666",
    main: "#FFFFFF00",
    dark: "#00000066",
};
const common = {
    consent: {
        wrap: {
            background: "rgba(0, 0, 0, 0.5)",
            borderColor: "rgba(255, 255, 255, 0.5)",
        },
    },
};
export default createTheme(theme, {
    palette: {
        primary,
        secondary,
        common,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `

      `,
        },
    },
});

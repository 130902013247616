import HomeTemplate from "@project/templates/home/HomeTemplate";
import ContentsTypes from "@shared/enums/contentsTypes";
export default {
    key: "HOME",
    label: "Accueil",
    templateImage: "/template-home.png",
    Component: HomeTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};

import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material";
import Box from "@mui/system/Box";
const StyleLayout = styled(Box) `
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.secondary.light};
  overflow-x: hidden;
`;
export default function Layout({ children }) {
    return _jsx(StyleLayout, { children: children });
}

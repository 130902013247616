import { create } from "zustand";
import data from "./data";
var SelectorCssOfAnimation;
(function (SelectorCssOfAnimation) {
    SelectorCssOfAnimation["SLIDE_IN"] = "home_template_animate__slideIn";
    SelectorCssOfAnimation["SLIDE_OUT"] = "home_template_animate__slideOut";
    SelectorCssOfAnimation["CURRENT_MENU"] = "home_template_animate__current_menu";
    SelectorCssOfAnimation["CURRENT_MENU_ACTIVE_ON_CLICK"] = "home_template_animate__current_menu_active_on_click";
    SelectorCssOfAnimation["MENU_NOT_ACTIVE"] = "home_template_animate__menu_not_active";
    SelectorCssOfAnimation["SLIDE_EMPTY"] = "";
})(SelectorCssOfAnimation || (SelectorCssOfAnimation = {}));
export const useMenuStore = create((set, get) => ({
    data,
    titleCurrentPage: "Les Entrées",
    selectorCssOfMenuAnimation: SelectorCssOfAnimation.SLIDE_EMPTY,
    rightTransitionMenu: () => set((state) => ({
        selectorCssOfMenuAnimation: state.selectorCssOfMenuAnimation === SelectorCssOfAnimation.SLIDE_IN
            ? SelectorCssOfAnimation.SLIDE_OUT
            : SelectorCssOfAnimation.SLIDE_EMPTY,
    })),
    leftTransitionMenu: (id) => set((state) => {
        const data = state.data.map((menu, index) => {
            menu.active = index === id;
            menu.className2 =
                index === id
                    ? SelectorCssOfAnimation.CURRENT_MENU_ACTIVE_ON_CLICK
                    : SelectorCssOfAnimation.MENU_NOT_ACTIVE;
            return menu;
        });
        const titleCurrentPage = [...data].find((menu) => menu.active)?.title || "";
        return {
            data,
            titleCurrentPage,
            selectorCssOfMenuAnimation: SelectorCssOfAnimation.SLIDE_IN,
        };
    }),
    subscribeToSelectorCssOfCurrentMenu: () => set((state) => ({
        data: state.data.map((menu) => {
            if (menu.active)
                menu.className = SelectorCssOfAnimation.CURRENT_MENU;
            delete menu.className2;
            return menu;
        }),
    })),
    unsubscribeToSelectorCssOfCurrentMenu: () => set((state) => ({
        data: state.data.map((menu) => {
            if (menu.className)
                delete menu.className;
            return menu;
        }),
    })),
}));

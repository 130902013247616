export default [
    {
        title: "Les entrées",
        linkImage: "./Rectangle_314.png",
        active: true,
    },
    {
        title: "Les plats",
        linkImage: "./Rectangle_315.png",
        active: false,
    },
    {
        title: "Les desserts",
        linkImage: "./Rectangle_316.png",
        active: false,
    },
];

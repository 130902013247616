import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import { StyleContentPage } from "@project/components/cards/contentPage/StyleContentPage";
export function ContentsOfAMenuPage(props) {
    const { position, title, description, image } = props;
    return (_jsxs(StyleContentPage, { sx: {
            flexDirection: !position ? "row" : "row-reverse",
        }, children: [_jsx(Box, { children: image }), _jsxs(Box, { children: [_jsx(Typography, { component: "h4", children: title }), _jsx(Typography, { component: "p", children: description })] })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useMenuStore } from "@project/templates/home/store/home.store";
import { StylePresentation } from "@project/templates/home/components/presentation/StylePresentation";
import { useEffect, useRef } from "react";
export function Presentation(props) {
    const titleCurrentPage = useMenuStore((state) => state.titleCurrentPage);
    const ref = useRef(null);
    const { page } = props;
    const { contents } = page;
    const { dynamicPart } = contents;
    useEffect(() => {
        if (ref.current) {
            const height = document.body.scrollHeight;
            let top;
            switch (titleCurrentPage) {
                case "Les plats":
                    top = height;
                    break;
                case "Les desserts":
                    top = height * 2;
                    break;
                default:
                    top = 0;
                    break;
            }
            ref.current.scrollTo({
                top,
                behavior: "smooth",
            });
        }
    }, [titleCurrentPage]);
    return (_jsx(StylePresentation, { children: _jsx(Box, { ref: ref, className: "home_template_animate__page_scroll_step", children: dynamicPart }) }));
}

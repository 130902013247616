import { Box, styled } from "@mui/material";
export const StyledBanner = styled(Box) `
  display: flex;
  width: 68vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0.4) 8.5%, rgba(102, 102, 102, 0) 100%),
    linear-gradient(1deg, rgba(0, 0, 0, 0.4) 14.93%, rgba(255, 255, 255, 0) 99.41%),
    url("./Rectangle_312.png") lightgray no-repeat;
  background-size: cover;

  & > div:nth-of-type(n) {
    width: inherit;
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 10px;
    padding: ${({ theme }) => theme.spacing(4)};
  }

  span {
    display: inline-block;
    color: ${({ theme }) => theme.palette.primary.text};
    font-weight: 400;
    font-family: "IBM Plex Serif Italic", serif;
  }

  & > div:nth-of-type(1) {
    align-items: flex-start;

    & > div:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ${({ theme }) => theme.spacing(2)};

      & > span:nth-of-type(1) {
        font-size: ${({ theme }) => theme.typography.fontSize};
        flex: 1;
      }

      & > span:nth-of-type(2) {
        cursor: pointer;
        font-size: ${({ theme }) => theme.typography.fontSize};
        border: 1px solid ${({ theme }) => theme.palette.primary.text};
        padding: ${({ theme }) => theme.spacing(1) + " " + theme.spacing(3)};

        &:hover {
          outline: none;
          background: #00a6c7;
        }
      }
    }
  }

  & > div:nth-of-type(2) {
    align-items: flex-end;

    & > span:nth-of-type(1) {
      font-family: "Ballet", serif;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 120px; /* 125% */
    }

    & > span:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ${({ theme }) => theme.spacing(2)};
      line-height: ${({ theme }) => theme.spacing(10)};
    }
  }
`;

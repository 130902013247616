import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { BannerHome } from "@project/templates/home/components/banner";
import { MenuHome } from "@project/templates/home/components/menu";
import { useMenuStore } from "@project/templates/home/store/home.store";
import { Presentation } from "@project/templates/home/components/presentation";
const StyleHome = styled(Box) `
  display: flex;
  width: 150vw;
  height: 100vh;
  gap: 1px;
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.secondary.light};
`;
export default function HomeTemplate(props) {
    const selectorCssOfMenuAnimation = useMenuStore((state) => state.selectorCssOfMenuAnimation);
    return (_jsxs(StyleHome, { className: selectorCssOfMenuAnimation, children: [_jsx(BannerHome, {}), _jsx(MenuHome, {}), _jsx(Presentation, { ...props })] }));
}

import { Box, styled } from "@mui/material";
export const StyleCardMenu = styled(Box) `
  width: 100%;
  cursor: pointer;
  height: 33.33vh;
  /*  flex: 1 0 0;
  align-self: stretch;*/

  &:active {
    color: #00a6c7;
  }

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    & > img {
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
    & > h4 {
      position: absolute;
      bottom: 0;
      left: 1.5rem;
      font-size: 1.8rem;
      color: ${({ theme }) => theme.palette.primary.text};
      font-family: "IBM Plex Serif Italic", serif;
      z-index: 100;
      display: inline-block;
      padding: 1rem;
    }
  }
`;

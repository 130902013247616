import CmsHooks from "@shared/utils/register/CmsHooks";
import { HOOKS } from "@shared/utils/register/definitions";
import Layout from "@project/components/ui/Layout";
import NotFound from "@project/components/ui/NotFound";
import templates from "@project/templates";
import theme from "./theme";
import getDynamicComponent from "@project/utils/getDynamicComponent";
import {
  ContentLabelType,
  setDataStaticConsentFromFront,
} from "@shared/components/cookies/config/definitions";

export default () => {
  CmsHooks.registerHook(HOOKS.PAGESWITCHER_404, NotFound);
  CmsHooks.registerHook(HOOKS.APP_LAYOUT, Layout);
  CmsHooks.registerHook(HOOKS.THEME, theme);
  CmsHooks.registerHook(HOOKS.TEMPLATES, templates);
  CmsHooks.registerHook(HOOKS.APP_ROUTES, []);
  CmsHooks.registerHook(
    HOOKS.TEMPLATEPROPS_UTILS_GETDYNAMICCOMPONENT,
    getDynamicComponent,
  );
  CmsHooks.registerHook(HOOKS.TRACKING_ACTIVE, true);
  CmsHooks.registerHook(
    HOOKS.CONSENT_CONFIG,
    setDataStaticConsentFromFront(
      true,
      ContentLabelType.MTM_CONSENT_REMOVED,
      ContentLabelType.ALLOW_THE_PROPER_FUNCTIONING_OF_THE_SITE,
      ContentLabelType.MTM_CONSENT_REMOVED,
      ContentLabelType.MATOMO_SESSID,
    ),
  );
};

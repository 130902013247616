import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ContentsOfAMenuPage } from "@project/components/cards/contentPage";
import customTypes from "@project/enums/contentsCustomTypes.enum";
import { PresentationOfAPage } from "@project/components/PresentationOfAPage";
const getDynamicComponent = ({ type, value, index }) => {
    switch (type) {
        case customTypes.CONTENT_PAGE:
            return _jsx(ContentsOfAMenuPage, { ...value });
        case customTypes.PRESENTATION_CONTENT_PAGE:
            return _jsx(PresentationOfAPage, { ...value });
        default:
            return null;
    }
};
export default getDynamicComponent;

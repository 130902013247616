import { Box } from "@mui/material";
import { styled } from "@mui/system";
export const StylePresentation = styled(Box) /*<{ $start: string; $end: string }>*/ `
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  background: #0d0e12;

  & > .home_template_animate__page_scroll_step {
    display: flex;
    height: 300vh;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 80%;
    margin: 0 auto;

    & > div {
      & > .wrapper_presentation {
        display: flex;
        width: 100%;
        min-height: 96vh;
        height: auto;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        overflow-y: scroll;
        scrollbar-width: none;
      }
    }
  }
`;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FacebookIcon } from "@project/components/ui/icons/facebook";
import { InstagramIcon } from "@project/components/ui/icons/instagram";
import { PhoneIcon } from "@project/components/ui/icons/phone";
import { MenuHamburgerIcon } from "@project/components/ui/icons/menuHamburger";
import { StyledBanner } from "./StyledBanner";
import { useMenuStore } from "@project/templates/home/store/home.store";
export function BannerHome() {
    const rightTransitionMenu = useMenuStore((state) => state.rightTransitionMenu);
    const subscribeToSelectorCssOfCurrentMenu = useMenuStore((state) => state.subscribeToSelectorCssOfCurrentMenu);
    const unsubscribeToSelectorCssOfCurrentMenu = useMenuStore((state) => state.unsubscribeToSelectorCssOfCurrentMenu);
    return (_jsxs(StyledBanner, { onClick: subscribeToSelectorCssOfCurrentMenu, onMouseOver: unsubscribeToSelectorCssOfCurrentMenu, onMouseDown: unsubscribeToSelectorCssOfCurrentMenu, children: [_jsxs(Box, { onClick: rightTransitionMenu, children: [_jsx(Box, { children: _jsx(MenuHamburgerIcon, {}) }), _jsxs(Box, { children: [_jsxs(Typography, { component: "span", children: [_jsx(Typography, { component: "span", children: _jsx(PhoneIcon, {}) }), _jsx(Typography, { component: "span", children: "06 78 65 98 43" })] }), _jsx(Typography, { component: "span", onClick: rightTransitionMenu, children: "R\u00E9server une table" })] })] }), _jsxs(Box, { children: [_jsx(Typography, { component: "span", children: "Paradis du palais" }), _jsxs(Typography, { component: "span", children: [_jsx("a", { onClick: (event) => event.preventDefault(), href: "/src/projects/RESTAURANT/public", target: "_blank", children: _jsx(FacebookIcon, {}) }), _jsx("a", { onClick: (event) => event.preventDefault(), href: "/src/projects/RESTAURANT/public", target: "_blank", children: _jsx(InstagramIcon, {}) })] })] })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { CardMenu } from "@project/templates/home/components/menu/menu";
import { styled, Box } from "@mui/material";
import { useMenuStore } from "@project/templates/home/store/home.store";
const StyleMenuList = styled(Box) `
  width: 34vw;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
`;
export function MenuHome() {
    const menus = useMenuStore((state) => state.data);
    const leftTransitionMenu = useMenuStore((state) => state.leftTransitionMenu);
    return (_jsx(StyleMenuList, { children: menus.map((menu, index) => (_jsx(CardMenu, { handleClickOnItem: leftTransitionMenu, index: index, ...menu }, index))) }));
}

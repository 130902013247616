import { styled } from "@mui/system";
import Box from "@mui/system/Box";
export const StyleContentPage = styled(Box) `
  display: flex;
  align-items: center;
  gap: 34px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;

  & > div:nth-of-type(1) {
    width: 146px;
    height: 187px;

    & > img {
      object-fit: inherit;
    }
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    & > h4 {
      color: #fff;

      font-family: "IBM Plex Serif", serif;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
    }

    & > p {
      color: #fff;
      font-family: "IBM Plex Serif", serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-align: justify;
    }
  }
`;
